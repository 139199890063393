import axios from 'axios';
import AppConfig from 'AppConfig';
import { getToken } from 'services/firebase';

const instance = axios.create({
  baseURL: AppConfig.apiUrl,
  headers: {
    'Content-Type': 'application/json'
  }
});

instance.interceptors.request.use(async function (config) {
  try {
    const idTokenResult = await getToken();
    if (idTokenResult?.token) {
      localStorage.setItem('edu_wa_session_token', idTokenResult.token);
    }
  } catch (error) {
    console.log('Error in api interceptors', error);
  }

  let token = localStorage.getItem('edu_wa_session_token');
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  config.headers['X-organisation-id'] = AppConfig?.organisationIdentity || '';
  return config;
});

export default instance;
