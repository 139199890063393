import process from 'process';
import configs from 'configs.json';

export default {
  env: process.env.REACT_APP_ENVIRONMENT,
  apiUrl: process.env.REACT_APP_API_URL,
  name: configs.name,
  organisationIdentity: configs.organisationIdentity,
  logo: configs.logo,
  colors: configs.colors
};
